<script lang="ts" setup>
import type { PlayersActivity } from "@/types";

const { isSpecialBox } = useSpecialMagicBox();

const defaultTypesDictionary = new Map([
	["bigwin", "big win"],
	["jackpot", "jackpot"],
	["daily-chests-accrual", isSpecialBox.value ? "Magic Lanterns" : "Magic box"]
]);

const props = defineProps<{ item: PlayersActivity }>();

const { t } = useT();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const isGuest = useIsGuest();

const isMagicBox = computed(() => props.item.type === "daily-chests-accrual");
const isJackpot = computed(() => props.item.type === "jackpot");
const isSweepStakes = computed(() => props.item.gameMode === "SweepStakes" && !isGuest.value);

const { open } = useTaoModals();
const { handleOpenGame } = useOpenGame(open, !isMagicBox.value);

const handleCheck = (winner: PlayersActivity) => {
	if (isJackpot.value) {
		return;
	}
	handleOpenGame(winner.data?.slug);
};
</script>

<template>
	<div class="feed-card" :class="'feed-card--' + item.type">
		<div
			class="feed-card__image"
			:class="{ 'with-pointer': isMagicBox || isJackpot ? isGuest : true }"
			@click="handleCheck(item)"
		>
			<NuxtImg
				v-if="defaultTypesDictionary.has(item.type)"
				:src="`/nuxt-img/activity-feed/${isMagicBox && isSpecialBox ? 'lanterns' : item.type}-bg.png`"
				:data-tid="item?.data?.slug"
				loading="lazy"
				format="webp"
				alt="bigwin"
			/>

			<NuxtImg
				v-else
				:src="`${baseImageUrl}${item.data?.logo}`"
				loading="lazy"
				:alt="item.data?.slug"
				:data-tid="item?.data?.slug"
			/>
		</div>
		<div class="feed-card__data">
			<div class="feed-card__text">
				<ABadge v-if="defaultTypesDictionary.has(item.type)" autosize background="var(--gradient-3)" class="mb-2">
					<AText type="badge-sm">{{ t(defaultTypesDictionary.get(item.type) ?? "") }}</AText>
				</ABadge>
				<AText type="small" class="feed-card__name app-text-clip" data-tid="winner-name">
					{{ item.name || item.nickname }} {{ item.surname }}.
				</AText>
			</div>
			<AText type="small" :modifiers="['bold']" as="div" class="feed-card__values">
				<template v-if="item.type === 'win'">
					<div
						v-if="item.data?.winAmount"
						class="feed-card__value"
						:data-tid="`win-amount-${item?.gameMode}`"
						:class="isSweepStakes ? 'feed-card__value--entries' : 'feed-card__value--coins'"
					>
						{{ numberFormat(item.data?.winAmount) }}
					</div>
				</template>
				<template v-if="item.type === 'bigwin'">
					<div
						class="feed-card__value feed-card__value--x"
						:class="isSweepStakes ? 'feed-card__value--entries' : 'feed-card__value--coins'"
						:data-tid="`bigwin-amount-${item?.gameMode}`"
					>
						{{ numberFormat(item.data?.big_win_coefficient || 0) }}
					</div>
				</template>
				<template v-if="item.type === 'jackpot'">
					<div
						class="feed-card__value"
						:data-tid="`jackpot-amount-${item?.gameMode}`"
						:class="isSweepStakes ? 'feed-card__value--entries' : 'feed-card__value--coins'"
					>
						{{ numberFormat(item.data?.amount || 0) }}
					</div>
				</template>
				<template v-if="item.type === 'daily-chests-accrual'">
					<div
						v-if="item.data?.coins"
						class="feed-card__value feed-card__value--coins"
						:data-tid="`daily-wheel-amount-${item?.gameMode}`"
					>
						{{ numberFormat(item.data?.coins) }}
					</div>
					<div
						v-if="item.data?.entries && (!isGuest || !item.data?.coins)"
						:class="['feed-card__value', isGuest ? 'feed-card__value--coins' : 'feed-card__value--entries']"
						:data-tid="`daily-wheel-amount-${item?.gameMode}`"
					>
						{{ numberFormat(item.data?.entries) }}
					</div>
				</template>
			</AText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.feed-card {
	height: 98px;
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
	display: flex;
	position: relative;

	&__image {
		width: 56px;
		height: 100%;
		background: var(--gray-800);
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		&.with-pointer {
			cursor: pointer;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}

	&__game {
		position: relative;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		color: var(--coro);
		text-shadow: 0 1px 1px var(--coronel);
		padding: 0 gutter(0.5) gutter(1.5);

		&:deep(span) {
			display: block;
			text-shadow: none;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
		}
	}
	&__image-overlay {
		z-index: 1;
	}
	&__values {
		display: flex;
		flex-direction: column;
	}
	@at-root {
		%value-icon {
			align-self: center;
			content: "";
			width: 20px;
			height: 20px;
			margin: 0 4px 0 0;
			background-size: contain !important;
			background-repeat: no-repeat;
		}
	}
	&__value {
		display: inline-flex;
		align-items: center;
		font-style: normal;
		color: var(--neutral);
	}
	&__value--coins::before {
		@extend %value-icon;
		background-image: url("/assets/icons/20/gold-coins.svg");
	}
	&__value--entries {
		&::before {
			@extend %value-icon;
			background-image: url("/assets/icons/20/sweep-coins.svg");
		}
	}
	&__value--x::before {
		@extend %value-icon;
		background-image: url("/assets/icons/16/big-win.svg");
	}
	&__data {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		flex-grow: 1;
		background: var(--gray-800);
		font-size: 14px;
		padding: 12px;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			padding: gutter(1.25) gutter(2);
		}
	}
	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}
	&__name {
		margin-bottom: 4px;
		color: var(--gray-300);
		&:first-letter,
		&:first-line {
			text-transform: capitalize;
		}
	}
}
</style>
